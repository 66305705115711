const controller = '/home'
export default axios => ({
  recommend(obj) {
    axios.defaults.headers.common['Authorization'] = null

    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    return axios.post(`${controller}/recommend${parmas}`, obj.obj)
  },
  
sendAgentContact(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.post(`${controller}/agent-contact`, obj)
  },
})
