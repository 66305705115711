const actions = {
  async getPropertyList({ commit }, params) {
    const res = await this.$repositories.property.getPropertyList(params)

    return res.data
  },
  async getPropertyDetails({ commit }, params) {
    const res = await this.$repositories.property.getPropertyDetails(params)

    return res.data
  },
  async search({ commit }, params) {
    const res = await this.$repositories.property.search(params)

    return res.data
  },
  async getHomePageStats({ commit }, params) {
    const res = await this.$repositories.stats.getHomePageStats(params)

    return res.data
  },
  async sendPropertyContact({ commit }, params) {
    const res = await this.$repositories.property.sendPropertyContact(params)

    return res.data
  },
  async getPast10daysListing({ commit }, params) {
    const res = await this.$repositories.property.getPast10daysListing(params)

    return res.data
  },
  async recommend({ commit }, params) {
    const res = await this.$repositories.home.recommend(params)

    return res.data
  },
  async sendAgentContact({ commit }, params) {
    const res = await this.$repositories.home.sendAgentContact(params)

    return res.data
  },
  
}
export default actions
