
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
    joinPopup:false
  }),
  computed: {
    ...mapState('config/site', ['siteConfig']),
    currentRouteName: {
      get() {
        return this.$route.path
      },
      set(newName) {
        return newName
      },
    },
    nextLnk() {
      switch (this.$route.path) {
        case '/feature':
          return '/property-manager-about'
        case '/property-manager-about':
          return '/owner-details'
        case '/owner-details':
          return '/residents'
        case '/residents':
          return '/recommend'
        case '/recommend':
          return '/pricing'
        case '/pricing':
          return '/contact'
        default:
          return 'dont-show'
      }
    },
  },
  watch: {
    '$route.path': {
      handler(val) {
        console.log(this.$route)
        this.currentRouteName = val
      },
      deep: true,
    },
  },
  methods:{
    ...mapMutations('config/site', ['setLogInDialogShow', 'setScheduleShow']),
    redirectToPage(){
      this.joinPopup=false
      this.$nuxt.$loading.start()
      window.open(`https://properdeeagents.com${this.$i18n.locale=='en'?'/en/feature':'/feature'}`,'_self')
    }
  }
}
