export default function ({
  $axios,
  $auth,
  req,
  $config: { baseURL, protocol },
  app,
  i18n
}) {
  $axios.setBaseURL(baseURL)

  // INTERCEPTOR
  $axios.onRequest(config => {
    let lang = 'en'
    if (i18n.locale === 'th') {
      lang = 'th'
    }
    config.params = { ...config.params, lang: lang }

    // TODO: Fix global loading on api caslls
    // app.components.NuxtLoading.methods.start()
    if (process.server) {
      config.headers.Origin = `${protocol}${req.headers.host}`
    }
    console.log("config",config)
  })

  // TODO: Fix global loading on api calls
  // $axios.onResponse(config => {
  //   app.components.NuxtLoading.methods.finish()
  // })
}
