import Vue from 'vue'

export default function ({app}) {
  if (!Vue.__VUETIFYLOCALE__) {
    Vue.__VUETIFYLOCALE__ = true
    Vue.mixin({
      // change Vuetify language when language is changed
      watch: {
        '$i18n.locale': {
          immediate: true,
          silentTranslationWarn: true,
          missingWarn: false, 
          fallbackWarn: false,
          handler(newLocale , oldLocale) {
            if (newLocale) {
              this.$vuetify.lang.current = newLocale
               if(oldLocale&&newLocale!=oldLocale){
                  //  setTimeout(() => {
                  //   window.location.reload();
                  // }, 500);
                }
            }
          },
        },
      },
    })
  }
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    console.log(oldLocale, newLocale)
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

}
