const resetWebState = () => ({
  errorSnackbar: { showing: false },
  headerHeight: null,
  isBlur: false,
  isScheduleShowOpen: {
    demo: false,
    visit: false,
  },
  isLogInDialogShowOpen: false,
  redirectUrl: null,
  loadingTimeout: 1300,
})

const mutations = {
  setSiteConfig(state, siteConfig) {
    state.siteConfig = siteConfig
  },
  setSiteConfigValues(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state.siteConfig[key] = val
    }
  },

  setErrorSnackbar(state, snackbar) {
    state.errorSnackbar = snackbar
  },
  closeErrorSnackbar(state) {
    state.errorSnackbar.showing = false
  },
  setHeaderHeight(state, height) {
    state.headerHeight = height
    if (!process.server) {
      document.documentElement.style.setProperty(
        '--headerHeight',
        `${height}px`
      )
    }
  },
  setBlur(state, isBlur) {
    state.isBlur = isBlur
  },

  setScheduleShow(state, isScheduleShowOpen) {
    // console.log('isScheduleShowOpen', isScheduleShowOpen)
    state.isScheduleShowOpen = isScheduleShowOpen
  },
  setLogInDialogShow(state, isLogInDialogShowOpen, redirectUrl) {
    // console.log('setLogInDialogShow', isLogInDialogShowOpen)
    state.isLogInDialogShowOpen = isLogInDialogShowOpen
    state.redirectUrl = redirectUrl
    if (!isLogInDialogShowOpen) {
      mutations.resetWebsiteState(state)
    }
  },
  resetWebsiteState(state) {
    // console.log('resetWebsiteState')
    Object.assign(state, resetWebState())
  },
  setTypeOflogin(state, obj) {
    // console.log('setTypeOflogin', obj)
    state.typeOfLogin = obj.typeOfLogin
  },
}
export default mutations
