const resetState = () => ({
    navMenus:[
      {
        url: '/home',
        name: 'Home',
      },
      {
        url: '/search?propertyListingTypeId=161&lat=13.7563&lng=100.5018',
        name: 'For Sale',
      },
      {
        url: '/search?propertyListingTypeId=162&lat=13.7563&lng=100.5018',
        name: 'For Rent',
      },
      {
        url: '/recommend',
        name: 'Recommended Agents',
      },
      {
        url: '/about-us',
        name: 'About Us',
      },
      {
        url: '/contact',
        name: 'Contact Us',
      },
    ],
    logo:'https://royalasia.blob.core.windows.net/default/ProperDee_transparent.png',
    homeUrl:'/home',
    showManagementLogin:false,
    isMobileMenuOpen: false,
})
const mutations = {
  setMobileHeader(state, val) {
    state.navMenus = val.navMenus
    state.logo = val.logo
    state.homeUrl = val.homeUrl
    state.showManagementLogin = val.showManagementLogin
  },
  setMobileMenuClick(state, isMobileMenuOpen) {
    state.isMobileMenuOpen = isMobileMenuOpen
    // console.log(state)
  },
  setMobileMenus(state, nav) {
    state.navMenus = nav
  },
  setMobileMenuLogo(state, logo) {
    state.logo = logo
  },
  setMobileMenuHomeUrl(state, homeUrl) {
    state.homeUrl = homeUrl
  },
  setMobileMenuManagementLoginBtn(state, showManagementLogin) {
    state.showManagementLogin = showManagementLogin
  },
  resetState(state) {
    Object.assign(state,resetState());
  },

}
export default mutations
