const state = () => ({
    navMenus:[
      {
        url: '/home',
        name: 'Home',
      },
      {
        url: '/search?propertyListingTypeId=161&lat=13.7563&lng=100.5018',
        name: 'For Sale',
      },
      {
        url: '/search?propertyListingTypeId=162&lat=13.7563&lng=100.5018',
        name: 'For Rent',
      },
      {
        url: '/recommend',
        name: 'Recommended Agents',
      },
      {
        url: '/about-us',
        name: 'About Us',
      },
      {
        url: '/contact',
        name: 'Contact Us',
      },
    ],
    logo:'https://royalasia.blob.core.windows.net/default/ProperDee_transparent.png',
    homeUrl:'/home',
    showManagementLogin:false,
    isMobileMenuOpen: false,
})
export default state
